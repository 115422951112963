import { createAsyncThunk } from '@reduxjs/toolkit'
import { cashOutBetHiLoService, myBetsHiLoService, getUnfinishedBetService, openCardHiLoService, placeBetHiLoService } from '../hiloGame.service'
import { BET_RESULT } from 'constants/index'
import { setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { resetCalculatedProfits, setCalculatedProfits } from './hiLoGame.slice'

export const placeBetHiLo = createAsyncThunk('hi-lo-game/place-bet', async ({ payload, buttonClickAudio }, thunkApi) => {
  try {
    const res = await placeBetHiLoService(payload)
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && buttonClickAudio?.play()
    openSuccessToaster({ message: 'Bet Placed Successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: 'Place Bet Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const openCardHiLo = createAsyncThunk('hi-lo-game/open-card', async ({ data, setBetAmount, minBetData, betAmount, handleChangeMainCard }, thunkApi) => {
  try {
    const res = await openCardHiLoService(data)
    const card = document.querySelector('.card-center')

    card.classList.toggle('flipped')
    const openedCard = res.betStates[res.betStates.length - 1].openedCard
    if (res?.result === BET_RESULT.LOST) {
      // openErrorToaster({ message: 'Player Lost' })
      thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
      thunkApi.dispatch(resetCalculatedProfits({
        currentCardIndex: openedCard
      }))
      setBetAmount(Number(minBetData))
    } else {
      thunkApi.dispatch(setCalculatedProfits({
        currentCardIndex: openedCard,
        coefficient: res.betStates[res.betStates.length - 1].coefficient,
        betAmount
      }))
    }
    handleChangeMainCard(openedCard)
    return res
  } catch (error) {
    console.log('CATCH ERROR', error)
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsHiLo = createAsyncThunk('hi-lo-game/my-bets', async (data, thunkApi) => {
  try {
    const res = await myBetsHiLoService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cashOutHiLo = createAsyncThunk('hi-lo-game/cash-out-bet', async ({ data, cashoutAudio }, thunkApi) => {
  try {
    const res = await cashOutBetHiLoService(data)
    thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && cashoutAudio?.play()
    return res
  } catch (error) {
    openErrorToaster({ message: 'Cash Out Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUnfinishedBet = createAsyncThunk('hi-lo-game/get-unfinished-bet', async (_, thunkApi) => {
  try {
    const res = await getUnfinishedBetService()
    if (res?.hasUnfinishedBet) {
      let coefficient = 1
      let currentCardIndex
      // openSuccessToaster({ message: 'You already have a Bet Placed.' })
      const betStates = res.unfinishedBet.betStates
      const betAmount = res.unfinishedBet.betAmount
      if (betStates.length) {
        coefficient = betStates[betStates.length - 1].coefficient
        currentCardIndex = betStates[betStates.length - 1].openedCard
      } else {
        currentCardIndex = res.unfinishedBet.initialCard
      }
      thunkApi.dispatch(setCalculatedProfits({
        currentCardIndex,
        betAmount,
        coefficient
      }))
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

// const calculateProfits = (currentCardValue, betAmount) => {
//   console.log("calculateProfits called with", currentCardValue, betAmount)
//   const totalCards = 52
//   const totalRanks = 13
//   const cardsPerRank = 4

//   // Calculate the number of higher and lower cards
//   const higherCards = (totalRanks - currentCardValue) * cardsPerRank
//   const lowerCards = (currentCardValue - 1) * cardsPerRank

//   // Calculate probabilities
//   const probabilityHigher = higherCards / totalCards
//   const probabilityLower = lowerCards / totalCards

//   // Assign multipliers (example: inverse of probability)
//   const multiplierHigher = 1 / probabilityHigher
//   const multiplierLower = 1 / probabilityLower

//   // Calculate profits
//   const profitHigher = betAmount * multiplierHigher
//   const profitLower = betAmount * multiplierLower
//   const totalProfit = profitHigher + profitLower

//   // Return results
//   return {
//     profitHigher: profitHigher.toFixed(4),
//     profitLower: profitLower.toFixed(4),
//     totalProfit: totalProfit.toFixed(4)
//   }
// }
