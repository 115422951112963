import * as PIXI from "pixi.js";
import { app } from "./appPixi";
import { setValuesToInitial } from "./gameLogic";
import { settings } from "./settings";
import { promiseAssets, themes } from "./assetsLoad";
import { spriteSettings } from "./utility";
import { THEMES_CONFIG } from "constants/index";

let uiCreated = false;

let appWidth = app.screen.width;
let appHeight = app.screen.height;

const appAspectRatio = app.screen.width / app.screen.height;

let appAspectRatioX = appWidth / 1920;
let appAspectRatioY = appHeight / 1080;

let loaderWidth = appWidth * 0.8;
let loaderHeight = 7 * appAspectRatioY;

const numOfXaxisDivision = 5;
const numOfYaxisDivision = 5;

let xGraphic;
let yGraphic;

const playerSize = 40 * appAspectRatioX;

const containers = {
  backgroundContainer: new PIXI.Container(),
  rootContainer: new PIXI.Container(),
  axisContainer: new PIXI.Container(),
  graphContainer: new PIXI.Container(),
  playerContainer: new PIXI.Container(),
  loadingContainer: new PIXI.Container(),
  centerTextContainer: new PIXI.Container(),
  crashContainer: new PIXI.Container(),
  cashoutContainer: new PIXI.Container(),
  networkStatusContainer: new PIXI.Container(),
  payOutContainer: new PIXI.Container(),
  crashedContainer: new PIXI.Container(),
  cashedOutContainer: new PIXI.Container(),
  graphMaskContainer: new PIXI.Container(),
};

const gameAssets = {
  player: null,
  playerCrashed: new PIXI.Sprite(),
  monkey: new PIXI.Sprite(),
  topLineMask: new PIXI.Sprite(),
  bottomLineMask: new PIXI.Sprite(),
  topLineGraph: new PIXI.Graphics(),
  topLineGradient: new PIXI.Sprite(),
  LineGraphInitialCurve: new PIXI.Graphics(),
  topLineGraphBottomCover: new PIXI.Graphics(),
  shadowTopLineGraph: new PIXI.Graphics(),
  bottomGraph: new PIXI.Graphics(),
  loaderBG: new PIXI.Sprite(),
  loaderFront: new PIXI.Graphics(),
  loaderBGShadow: new PIXI.Graphics(),
  loaderPercent: new PIXI.Graphics(),
  graphMask: new PIXI.Graphics(),

  centerBigTextBG: new PIXI.Sprite(),
  dollar: new PIXI.Sprite(),

  xAxis: [],
  yAxis: [],

  loadingText: "",
  centerBigText: "",
  cashedOutText: "",
  multiplierText: "",
  amountText: "",

  playerCount: "",
  playerOnlineSignal: new PIXI.Graphics(),

  crashBG: new PIXI.Graphics(),
  crashBGShadow: new PIXI.Graphics(),
  crashText: "",

  networkStatusText: "",
  networkSignal: new PIXI.Graphics(),

  cashoutBG: new PIXI.Graphics(),
  cashoutBGShadow: new PIXI.Graphics(),
  cashoutText: "",
  cashOutAmountText: "",

  // Axis
  axisDots: {
    x: [],
    y: [],
  },

  background: [],
};

const fonts = {
  proximaFont: {},
  interBold: {},
  interSemibold: {},
  interMedium: {},
  mulishReg: {},
  interReg: {},
};

function createUI() {
  setupFonts();
  setupContainers();
  setupGameAssets();
  uiCreated = true;
}

function setupFonts() {
  fonts.interBold.fontFamily = promiseAssets.interbold.family;
  fonts.interSemibold.fontFamily = promiseAssets.intersemibold.family;
  fonts.interMedium.fontFamily = promiseAssets.intermed.family;
  fonts.mulishReg.fontFamily = promiseAssets.mulishreg.family;
  fonts.interReg.fontFamily = promiseAssets.interRegular.family;
}

function setupContainers() {
  app.stage.addChild(containers.rootContainer);

  containers.rootContainer.addChild(containers.backgroundContainer);
  containers.rootContainer.addChild(containers.graphContainer);
  containers.rootContainer.addChild(containers.axisContainer);
  containers.rootContainer.addChild(containers.playerContainer);
  containers.rootContainer.addChild(containers.centerTextContainer);
  containers.centerTextContainer.addChild(containers.payOutContainer);
  containers.centerTextContainer.addChild(containers.crashedContainer);
  containers.centerTextContainer.addChild(containers.cashedOutContainer);
  containers.rootContainer.addChild(containers.crashContainer);
  containers.rootContainer.addChild(containers.cashoutContainer);
  containers.rootContainer.addChild(containers.loadingContainer);
  containers.rootContainer.addChild(containers.networkStatusContainer);
  containers.rootContainer.addChild(containers.graphMaskContainer);
  containers.centerTextContainer.position.set(appWidth / 2, appHeight * 0.45);
}

function setupGameAssets() {
  setupBackground();
  createAxis();
  createLoadingScreen();
  createPlayer();
  createGraph();
  createNetworkStatus();
  createCenterText();

  //This method must always be called at last
  setValuesToInitial();
}

function setupBackground() {
  const backgroundMask = new PIXI.Graphics()
  backgroundMask.beginFill(settings.themeColors[themes.currentTheme].axis, 1);
  backgroundMask.drawRect(appWidth*0.02, 0, appWidth*0.92, appHeight)
  backgroundMask.endFill()
  containers.rootContainer.addChild(backgroundMask)

  for (let index = 0; index < 4; index++) {
    let background = new PIXI.Sprite();

    background = new PIXI.Sprite(PIXI.Assets.get("stars"))
    background.anchor.set(0.5)
    containers.backgroundContainer.addChild(background)
    background.scale.set(0.7 * appAspectRatioX);
    gameAssets.background.push(background);
    if (index % 2 > 0) {
      background.scale.set(background.scale.x * -1, background.scale.y);
    }

    background.alpha = settings.themeColors[themes.currentTheme].bgAlpha
  }

  containers.backgroundContainer.mask = backgroundMask
}

function createGradTexture() {
  // adjust it if somehow you need better quality for very very big images
  const quality = 256;
  const canvas = document.createElement("canvas");

  canvas.width = quality;
  canvas.height = 1;

  const ctx = canvas.getContext("2d");

  // use canvas2d API to create gradient
  const grd = ctx.createLinearGradient(0, 0, quality, 1);
  grd.addColorStop(0.0, "#ffffff");
  grd.addColorStop(1, "#ffffff");

  // grd.addColorStop(0.0, '#513196');
  // grd.addColorStop(0.2, '#513196');
  // grd.addColorStop(0.4, '#dd53db');
  // grd.addColorStop(0.6, '#fa5395');
  // grd.addColorStop(0.8, '#fa683a');
  // grd.addColorStop(1, '#fa683a');

  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, quality, 1);

  return PIXI.Texture.from(canvas);
}

function createLoadingScreen() {
  containers.loadingContainer.position.set(appWidth * 0.5, appHeight * 0.5);
  containers.loadingContainer.pivot.x = containers.loadingContainer.width / 2;
  containers.loadingContainer.pivot.y = containers.loadingContainer.height / 2;

  gameAssets.loaderBG = spriteSettings(
    gameAssets.loaderBG,
    PIXI.Assets.get("completeAssets").textures.loaderBG,
    containers.loadingContainer
  );
  gameAssets.loaderBG.scale.set(0.8 * appAspectRatioX);
  gameAssets.loaderBG.tint = settings.themeColors[themes.currentTheme].loadingTint

  gameAssets.loadingText = new PIXI.Text(
    "Next round in: 5.0s",
    new PIXI.TextStyle({
      fontFamily: fonts.interMedium.fontFamily,
      fill: [settings.themeColors[themes.currentTheme].loadingText],
      fontSize: 50,
    })
  );
  gameAssets.loadingText.anchor.set(0.5);
  gameAssets.loadingText.scale.set(1.2 * appAspectRatioX);
  containers.loadingContainer.addChild(gameAssets.loadingText);

  containers.loadingContainer.visible = false;
}

function createAxis() {
  createYaxis();
  createXaxis();
}

const yAxisInitial = appHeight * settings.axisGapY * 1;
const yAxisFinal = appHeight * 0.1;
const yAxisDiff = (yAxisInitial - yAxisFinal) / numOfYaxisDivision;

const xAxisInitial = appWidth * settings.axisGapX * 1;
const xAxisFinal = appWidth * 0.9;
const xAxisDiff = (xAxisFinal - xAxisInitial) / numOfXaxisDivision;

function createYaxis() {
  const xPos = xAxisInitial;

  const width = 100 * appAspectRatioX;
  const height = 80 * appAspectRatioY;

  const yBehindGraphic = new PIXI.Graphics();
  yBehindGraphic.lineStyle(1, settings.themeColors[themes.currentTheme].axis);
  yBehindGraphic.moveTo(xPos, yAxisInitial);
  yBehindGraphic.lineTo(xPos, yAxisFinal);
  yBehindGraphic.endFill();
  containers.axisContainer.addChild(yBehindGraphic);

  for (let index = 0; index <= numOfYaxisDivision; index++) {
    const yAxisMark = {
      graphic: new PIXI.Graphics(),
      yAxisText: new PIXI.Text(
        "",
        new PIXI.TextStyle({
          fontFamily: fonts.interSemibold.fontFamily,
          fill: settings.themeColors[themes.currentTheme].axis,
          fontSize: 40,
          fontWeight: "bold",
          align: "right",
        })
      ),
    };

    const yPos = yAxisInitial - yAxisDiff * index;
    yAxisMark.graphic.lineStyle(0);
    yAxisMark.graphic.beginFill(settings.themeColors[themes.currentTheme].axis, 1);
    yAxisMark.graphic.drawCircle(xPos, yPos, width * 0.1);
    yAxisMark.graphic.endFill();

    yAxisMark.yAxisText.text = index;
    yAxisMark.yAxisText.tint = settings.themeColors[themes.currentTheme].axis;
    yAxisMark.graphic.addChild(yAxisMark.yAxisText);

    containers.axisContainer.addChild(yAxisMark.graphic);
    gameAssets.yAxis.push(yAxisMark.yAxisText);
    yAxisMark.yAxisText.anchor.set(0.5);
    yAxisMark.yAxisText.scale.set(appAspectRatioX * 1);
    yAxisMark.yAxisText.position.set(
      xPos - yAxisMark.yAxisText.width * 2.5,
      yPos
    );
  }
}

function createXaxis() {
  const yPos = yAxisInitial;

  const width = 100 * appAspectRatioX;
  const height = 80 * appAspectRatioY;

  const xBehindGraphic = new PIXI.Graphics();
  xBehindGraphic.lineStyle(1, settings.themeColors[themes.currentTheme].axis);
  xBehindGraphic.moveTo(xAxisInitial, yAxisInitial);
  xBehindGraphic.lineTo(xAxisFinal, yAxisInitial);
  xBehindGraphic.endFill();
  containers.axisContainer.addChild(xBehindGraphic);

  for (let index = 0; index <= numOfXaxisDivision; index++) {
    const xAxisMark = {
      graphic: new PIXI.Graphics(),
      xAxisText: new PIXI.Text(
        "",
        new PIXI.TextStyle({
          fontFamily: fonts.interSemibold.fontFamily,
          fill: settings.themeColors[themes.currentTheme].axis,
          fontSize: 40,
          fontWeight: "bold",
          align: "right",
        })
      ),
    };

    const xPos = xAxisInitial + xAxisDiff * index;
    xAxisMark.graphic.lineStyle(0);
    xAxisMark.graphic.beginFill(settings.themeColors[themes.currentTheme].axis, 1);
    xAxisMark.graphic.drawCircle(xPos, yPos, width * 0.1);
    xAxisMark.graphic.endFill();

    xAxisMark.xAxisText.text = index;
    xAxisMark.xAxisText.tint = settings.themeColors[themes.currentTheme].axis;

    containers.axisContainer.addChild(xAxisMark.graphic);
    gameAssets.xAxis.push(xAxisMark.xAxisText);
    if (!index == 0) {
      xAxisMark.graphic.addChild(xAxisMark.xAxisText);
    }

    xAxisMark.xAxisText.anchor.set(0.5);
    xAxisMark.xAxisText.scale.set(appAspectRatioX * 1);
    xAxisMark.xAxisText.position.set(
      xPos,
      yPos + xAxisMark.xAxisText.height * 0.8
    );
  }
}

function createNetworkStatus() {
  gameAssets.networkStatusText = new PIXI.Text(
    "Network Status",
    new PIXI.TextStyle({
      fontFamily: "arial",
      fill: settings.themeColors[themes.currentTheme].axis,
      fontSize: 50,
    })
  );
  gameAssets.networkStatusText.tint = 0x97a1a8;
  gameAssets.networkStatusText.anchor.set(0.5);
  gameAssets.networkStatusText.scale.set(appAspectRatioX * 3);
  gameAssets.networkStatusText.position.set(
    gameAssets.networkStatusText.width * 0,
    gameAssets.networkStatusText.height * 0.25
  );
  containers.networkStatusContainer.addChild(gameAssets.networkStatusText);
  containers.networkStatusContainer.position.set(
    appWidth * 0.9 - containers.networkStatusContainer.width * 0.1,
    appHeight * settings.axisGapY - 20
  );
  containers.networkStatusContainer.scale.set(0.6 * appAspectRatioX);

  containers.networkStatusContainer.addChild(gameAssets.networkSignal);
}

function createPlayer() {
  gameAssets.player = new PIXI.AnimatedSprite(PIXI.Assets.get("rocket").animations.frames)
  containers.playerContainer.addChild(gameAssets.player)
  gameAssets.player.play()
  // gameAssets.player = spriteSettings(
  //   gameAssets.player,
  //   PIXI.Assets.get("completeAssets").textures.rocket,
  //   containers.playerContainer
  // );
  gameAssets.player.scale.set(1 * appAspectRatioX);
  gameAssets.player.position.set(40 * appAspectRatioX, -40 * appAspectRatioX);
  gameAssets.player.animatedSpeed = 1.5
  containers.playerContainer.position.set(
    appWidth * settings.axisGapX,
    appHeight * settings.axisGapY
  );
  gameAssets.player.visible = true

  gameAssets.playerCrashed = spriteSettings(
    gameAssets.playerCrashed,
    PIXI.Assets.get("completeAssets").textures.blast,
    containers.playerContainer
  )
  gameAssets.playerCrashed.visible = false
  gameAssets.playerCrashed.scale.set(1 * appAspectRatioX);
  gameAssets.playerCrashed.position.set(40 * appAspectRatioX, -40 * appAspectRatioX);

  switch (themes.currentTheme) {
    case THEMES_CONFIG[0].slug: // default
      gameAssets.player.anchor.set(0.5, 0.46);
      gameAssets.player.scale.set(0.7 * appAspectRatioX);

      break;

    case THEMES_CONFIG[1].slug: // tgc
      gameAssets.player.anchor.set(0.58, 0.58);
      gameAssets.player.scale.set(appAspectRatioX);

      break;

    case THEMES_CONFIG[2].slug: // lb
      gameAssets.player.anchor.set(0.58, 0.4);
      gameAssets.player.scale.set(appAspectRatioX);

      break;

    case THEMES_CONFIG[3].slug: // wsm
      gameAssets.player.anchor.set(0.67, 0.42);
      gameAssets.player.scale.set(appAspectRatioX);

    break;


    default:
      gameAssets.player.anchor.set(0.5, 0.46);

      break;
  }

  gameAssets.playerCrashed.anchor = gameAssets.player.anchor
  gameAssets.playerCrashed.scale = gameAssets.player.scale

}

function createGraph() {
  gameAssets.topLineMask = spriteSettings(
    gameAssets.topLineMask,
    createGradTexture(),
    containers.graphContainer
  );
  gameAssets.topLineMask.position.set(appWidth / 2, appHeight / 2);
  gameAssets.topLineMask.width = appWidth;
  gameAssets.topLineMask.height = appHeight;
  gameAssets.topLineMask.alpha = 0;

  gameAssets.bottomLineMask = spriteSettings(
    gameAssets.bottomLineMask,
    PIXI.Assets.get("completeAssets").textures.blastpopup,
    containers.graphContainer
  );
  gameAssets.bottomLineMask.position.set(appWidth / 2, appHeight / 2);
  gameAssets.bottomLineMask.width = appWidth;
  gameAssets.bottomLineMask.height = appHeight;
  gameAssets.bottomLineMask.alpha = 0;

  gameAssets.bottomGraph.lineStyle(0);
  gameAssets.bottomGraph.beginFill(settings.bottomGraphColor, 1);
  containers.graphContainer.addChild(gameAssets.bottomGraph);

  gameAssets.topLineGraph.lineStyle(
    15 * appAspectRatioX,
    settings.topGraphColor
  );
  containers.graphContainer.addChild(gameAssets.topLineGraph);

  // gameAssets.topLineMask.mask = gameAssets.topLineGraph;
  gameAssets.LineGraphInitialCurve.lineStyle(0);
  gameAssets.LineGraphInitialCurve.beginFill(0xffe500, 0.01);
  gameAssets.LineGraphInitialCurve.drawCircle(0, 0, 10 * appAspectRatioX);
  gameAssets.LineGraphInitialCurve.endFill();
  containers.graphContainer.addChild(gameAssets.LineGraphInitialCurve);
  gameAssets.LineGraphInitialCurve.position.set(
    appWidth * settings.axisGapX,
    appHeight * settings.axisGapY
  );

  gameAssets.graphMask.beginFill(settings.blue, 0.001);
  gameAssets.graphMask.drawRoundedRect(0, 0, 0, appHeight, 5);
  gameAssets.graphMask.endFill();

  containers.graphMaskContainer.addChild(gameAssets.graphMask);

  containers.graphContainer.mask = gameAssets.graphMask;
}

function createCenterText() {
  gameAssets.centerBigTextBG = spriteSettings(
    gameAssets.centerBigTextBG,
    PIXI.Assets.get("completeAssets").textures.popup,
    containers.payOutContainer
  );
  gameAssets.centerBigTextBG.scale.set(
    appAspectRatioX * 0.7,
    appAspectRatioX * 0.6
  );
  gameAssets.centerBigTextBG.position.set(0, 0);

  gameAssets.centerBigText = new PIXI.Text(
    "1.0x",
    new PIXI.TextStyle({
      fontFamily: fonts.interBold.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 100,
    })
  );
  gameAssets.centerBigText.anchor.set(0.5);
  gameAssets.centerBigText.scale.set(1 * appAspectRatioX);
  gameAssets.centerBigText.position.set(0, 0);
  containers.payOutContainer.addChild(gameAssets.centerBigText);

  containers.payOutContainer.position.set(
    0,
    -gameAssets.centerBigTextBG.height * 0.3
  );

  gameAssets.crashCenterText = new PIXI.Text(
    "CRASHED!",
    new PIXI.TextStyle({
      fontFamily: fonts.interMedium.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 50,
      align: "center",
    })
  );
  gameAssets.crashCenterText.anchor.set(0.5);
  gameAssets.crashCenterText.scale.set(0.9 * appAspectRatioX);
  gameAssets.crashCenterText.position.set(
    0,
    gameAssets.centerBigTextBG.height * 0.6
  );
  containers.payOutContainer.addChild(gameAssets.crashCenterText);

  gameAssets.cashedOutText = new PIXI.Text(
    "Cashed out:",
    new PIXI.TextStyle({
      fontFamily: fonts.interMedium.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 50,
      align: "center",
    })
  );
  gameAssets.cashedOutText.anchor.set(0.5);
  gameAssets.cashedOutText.scale.set(0.7 * appAspectRatioX);
  gameAssets.cashedOutText.position.set(
    0,
    gameAssets.centerBigTextBG.height * 1.45
  );
  containers.payOutContainer.addChild(gameAssets.cashedOutText);

  gameAssets.multiplierText = new PIXI.Text(
    "2.6x",
    new PIXI.TextStyle({
      fontFamily: fonts.interMedium.fontFamily,
      fill: ["#2CB575"],
      fontSize: 50,
      align: "center",
    })
  );
  gameAssets.multiplierText.anchor.set(0.5);
  gameAssets.multiplierText.scale.set(0.7 * appAspectRatioX);
  gameAssets.multiplierText.position.set(
    0,
    gameAssets.centerBigTextBG.height * 1.45
  );
  containers.payOutContainer.addChild(gameAssets.multiplierText);

  gameAssets.dollar = spriteSettings(
    gameAssets.dollar,
    PIXI.Assets.get("completeAssets").textures.dollar,
    containers.payOutContainer
  );
  gameAssets.dollar.scale.set(1 * appAspectRatioX);

  gameAssets.amountText = new PIXI.Text(
    "$ 6.00",
    new PIXI.TextStyle({
      fontFamily: fonts.interMedium.fontFamily,
      fill: ["#2CB575"],
      fontSize: 50,
      align: "center",
    })
  );
  gameAssets.amountText.anchor.set(0.5);
  gameAssets.amountText.scale.set(0.7 * appAspectRatioX);
  gameAssets.amountText.position.set(
    0,
    gameAssets.centerBigTextBG.height * 1.45
  );
  containers.payOutContainer.addChild(gameAssets.amountText);

  gameAssets.cashedOutText.visible = false;
  gameAssets.multiplierText.visible = false;
  gameAssets.amountText.visible = false;
  gameAssets.dollar.visible = false;
}

function createCrashScreen() {
  gameAssets.crashBGShadow.lineStyle();
  gameAssets.crashBGShadow.beginFill(0x000000, 0.05);
  gameAssets.crashBGShadow.drawRoundedRect(
    appWidth / 2 - (loaderWidth * 0.8) / 2,
    appHeight / 2 + loaderHeight * 0.9 + 10,
    loaderWidth * 0.8,
    loaderHeight * 0.9,
    5
  );
  containers.crashContainer.addChild(gameAssets.crashBGShadow);

  gameAssets.crashBG.lineStyle(0, 0x253742, 1);
  gameAssets.crashBG.beginFill(0x253742, 1);
  gameAssets.crashBG.drawRoundedRect(
    appWidth / 2 - (loaderWidth * 0.8) / 2,
    appHeight / 2 + loaderHeight * 0.9,
    loaderWidth * 0.8,
    loaderHeight * 0.9,
    5
  );
  containers.crashContainer.addChild(gameAssets.crashBG);

  gameAssets.crashText = new PIXI.Text(
    "Crashed",
    new PIXI.TextStyle({
      fontFamily: fonts.proximaFont.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 50,
    })
  );
  gameAssets.crashText.scale.set(1.3 * appAspectRatioX);
  gameAssets.crashText.anchor.set(0.5);
  gameAssets.crashText.position.set(
    appWidth * 0.5,
    appHeight / 2 + loaderHeight * 1.3
  );
  containers.crashContainer.addChild(gameAssets.crashText);

  containers.crashContainer.visible = false;
}

function createCashoutScreen() {
  gameAssets.cashoutBG.lineStyle();
  gameAssets.cashoutBG.beginFill(0x000000, 0.05);
  gameAssets.cashoutBG.drawRoundedRect(
    appWidth / 2 - (loaderWidth * 1.1) / 2,
    appHeight / 2 + loaderHeight * 0.9 + 10,
    loaderWidth * 1.1,
    loaderHeight * 0.9,
    5
  );
  containers.cashoutContainer.addChild(gameAssets.cashoutBG);

  gameAssets.cashoutBGShadow.lineStyle(0, 0x253742, 1);
  gameAssets.cashoutBGShadow.beginFill(0x253742, 1);
  gameAssets.cashoutBGShadow.drawRoundedRect(
    appWidth / 2 - (loaderWidth * 1.1) / 2,
    appHeight / 2 + loaderHeight * 0.9,
    loaderWidth * 1.1,
    loaderHeight * 0.9,
    5
  );
  containers.cashoutContainer.addChild(gameAssets.cashoutBGShadow);

  gameAssets.cashoutText = new PIXI.Text(
    "Cashed Out",
    new PIXI.TextStyle({
      fontFamily: fonts.proximaFont.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 50,
    })
  );
  gameAssets.cashoutText.scale.set(1.3 * appAspectRatioX);
  gameAssets.cashoutText.anchor.set(0.5);
  gameAssets.cashoutText.position.set(
    appWidth * 0.47,
    appHeight / 2 + loaderHeight * 1.3
  );
  containers.cashoutContainer.addChild(gameAssets.cashoutText);

  gameAssets.cashOutAmountText = new PIXI.Text(
    "",
    new PIXI.TextStyle({
      fontFamily: fonts.proximaFont.fontFamily,
      fill: ["#FFFFFF"],
      fontSize: 50,
    })
  );
  gameAssets.cashOutAmountText.scale.set(1.3 * appAspectRatioX);
  gameAssets.cashOutAmountText.anchor.set(0.5);
  gameAssets.cashOutAmountText.position.set(
    appWidth * 0.595,
    appHeight / 2 + loaderHeight * 1.3
  );
  containers.cashoutContainer.addChild(gameAssets.cashOutAmountText);

  containers.cashoutContainer.visible = false;
}

export {
  containers,
  gameAssets,
  createUI,
  uiCreated,
  appAspectRatio,
  appAspectRatioX,
  loaderWidth,
  loaderHeight,
  numOfXaxisDivision,
  numOfYaxisDivision,
};
