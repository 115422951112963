import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomRoutes from './routes'
import Loader from 'components/ui-kit/Loader/index'

// import ThemeSwitcher from 'components/ui-kit/ThemeSwitcher/ThemeSwitcher'
// import { ThemeProvider } from 'ThemeContext'

const Routes = () => (
  <Router>
    {/* <ThemeProvider> */}
      {/* <ThemeSwitcher /> */}
      <Suspense fallback={<Loader />}>
        <CustomRoutes />
      </Suspense>
    {/* </ThemeProvider> */}
  </Router>
);

export default Routes
