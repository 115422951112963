import { createAsyncThunk } from '@reduxjs/toolkit'
// import { v4 as uuidv4 } from 'uuid'
import { getDiceGameBetHistory, diceGamePlaceBet, diceGameDemoPlaceBet } from 'games/DiceGame/dice.service'
// import { setDemoWallet } from '../redux/slices/gameSettings.slice'
import { setDiceBetHistory, setDiceLossCount, setDiceWinsCount, setProfit, setWagered } from 'games/DiceGame/slice-thunk/diceGame.slice'
// import { setCustomGameTransactions } from '../redux/slices/casino.slice'
import {
  setDiceStrategyResultArray,
  setLoseStreakCount,
  setStrategyBetCount,
  setStrategyLoseCount,
  setStrategyWinCount,
  setWinStreakCount
} from 'games/DiceGame/slice-thunk/diceStrategy.slice'
import { BET_RESULT } from 'constants/index'
import { openErrorToaster } from 'helpers/toaster.helpers'
import { generateClientSeed, getPrecision } from 'helpers/common.helpers'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { updateDemoWalletBalance } from 'redux-store/redux/slices/auth.slice'

export const getDiceGameBetRecord = createAsyncThunk(
  'diceGame/get-bet-history', async ({ limit }, thunkApi) => {
    try {
      const res = await getDiceGameBetHistory({ limit })
      return res
    } catch (error) {
      openErrorToaster(error[0].description)
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const diceGamePlacedBet = createAsyncThunk(
  'diceGame/place-bet', async ({ payload, isDemoGame, handleCancelAutoBet }, thunkApi) => {
    try {
      const res = await diceGamePlaceBet({ data: payload, isDemoGame })
      const { diceBetHistory, wagered, profit } = thunkApi?.getState()?.diceGame
      const { isStrategyMode, strategyResultArray } = thunkApi?.getState()?.diceStrategy
      // const { customGameTransactions } = thunkApi?.getState()?.games
      // const { user } = thunkApi?.getState()?.user

      if (res?.nextServerSeedHash) {
        const newClientSeed = generateClientSeed()
        thunkApi.dispatch(setNewClientSeed(newClientSeed))
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }

      let newBetHistory = {}
      let rows = [{ ...res }]
      if (diceBetHistory) {
        rows = [...rows, ...diceBetHistory?.rows]
      }

      // const updatedCustomGameTransactions = {
      //   ...customGameTransactions,
      //   rows: [{
      //     betId: Number(res?.id) || uuidv4(),
      //     gameId: '2',
      //     win: Number(res?.winningAmount),
      //     bet: Number(res?.betAmount),
      //     betCreatedAt: res?.createdAt,
      //     game: { name: 'dice' },
      //     user: {
      //       userName: user ? user?.userName : 'DEMO'
      //     }
      //   },
      //   ...customGameTransactions?.rows
      //   ],
      //   count: customGameTransactions?.count + 1
      // }

      newBetHistory = { count: diceBetHistory?.count ? diceBetHistory?.count + 1 : 1, rows }
      thunkApi.dispatch(setDiceBetHistory(newBetHistory))
      // thunkApi.dispatch(setDemoWallet(res?.demoAmount))
      // thunkApi.dispatch(setCustomGameTransactions(updatedCustomGameTransactions))

      if (res?.result) {
        thunkApi.dispatch(setDiceStrategyResultArray([...strategyResultArray, res?.result]))
      }
      if (isStrategyMode) {
        if (res?.result === BET_RESULT.WON) {
          thunkApi.dispatch(setWinStreakCount())
          thunkApi.dispatch(setLoseStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyWinCount())
          thunkApi.dispatch(setStrategyBetCount())
        } else {
          thunkApi.dispatch(setLoseStreakCount())
          thunkApi.dispatch(setWinStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyLoseCount())
          thunkApi.dispatch(setStrategyBetCount())
        }
      }

      const totalProfit = profit + parseFloat(res?.winningAmount) - parseFloat(res?.betAmount)
      const totalWagered = wagered + parseFloat(res?.betAmount)
      if (res?.result === BET_RESULT.WON) {
        thunkApi.dispatch(setDiceWinsCount())
      } else {
        thunkApi.dispatch(setDiceLossCount())
      }
      thunkApi.dispatch(setProfit(getPrecision(totalProfit, 2)))
      thunkApi.dispatch(setWagered(getPrecision(totalWagered, 2)))

      return res
    } catch (error) {
      console.log(error)
      openErrorToaster(error[0].description)
      handleCancelAutoBet()
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const diceGameDemoPlacedBet = createAsyncThunk(
  'diceGame/place-bet', async ({ payload, handleCancelAutoBet }, thunkApi) => {
    try {
      const res = await diceGameDemoPlaceBet({ data: payload })
      const { diceBetHistory, wagered, profit } = thunkApi?.getState()?.diceGame
      const { isStrategyMode, strategyResultArray } = thunkApi?.getState()?.diceStrategy

      if (res?.nextServerSeedHash) {
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }

      let newBetHistory = {}
      let rows = [{ ...res }]
      if (diceBetHistory) {
        rows = [...rows, ...diceBetHistory?.rows]
      }

      newBetHistory = { count: diceBetHistory?.count ? diceBetHistory?.count + 1 : 1, rows }
      thunkApi.dispatch(setDiceBetHistory(newBetHistory))

      if (res?.result) {
        thunkApi.dispatch(setDiceStrategyResultArray([...strategyResultArray, res?.result]))
        thunkApi.dispatch(updateDemoWalletBalance(res?.demoBalance))
      }
      if (isStrategyMode) {
        if (res?.result === BET_RESULT.WON) {
          thunkApi.dispatch(setWinStreakCount())
          thunkApi.dispatch(setLoseStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyWinCount())
          thunkApi.dispatch(setStrategyBetCount())
        } else {
          thunkApi.dispatch(setLoseStreakCount())
          thunkApi.dispatch(setWinStreakCount({ reset: 0 }))
          thunkApi.dispatch(setStrategyLoseCount())
          thunkApi.dispatch(setStrategyBetCount())
        }
      }

      const totalProfit = profit + parseFloat(res?.winningAmount) - parseFloat(res?.betAmount)
      const totalWagered = wagered + parseFloat(res?.betAmount)
      if (res?.result === BET_RESULT.WON) {
        thunkApi.dispatch(setDiceWinsCount())
      } else {
        thunkApi.dispatch(setDiceLossCount())
      }
      thunkApi.dispatch(setProfit(getPrecision(totalProfit, 2)))
      thunkApi.dispatch(setWagered(getPrecision(totalWagered, 2)))

      return res
    } catch (error) {
      console.log(error)
      openErrorToaster(error[0].description)
      handleCancelAutoBet()
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
