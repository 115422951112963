import { createUI } from "./gameUI";
import {
  startGame,
  crashGame,
  setValuesToInitial,
  loadGame,
  divideOnXaxis,
  setCenterText,
  getSignal,
  changeSignalStatus,
  setCashoutAmount,
  setUserCashoutPercentage,
} from "./gameLogic";
import { assetsLoaded } from "./assetsLoad";

export function gameManager() {
  createUI();
}

export function resetGame() {
  if (!assetsLoaded) return;
  setValuesToInitial();
}


export function start() {
  if (!assetsLoaded) return;
  startGame();
}

export function crash(crashedAt) {
  if (!assetsLoaded) return;
  crashGame(crashedAt);
}

export function startLoading(loadTime) {
  if (!assetsLoaded) return;
  loadGame(loadTime);
}

export function createCashOut(num1, num2, currencySymbol) {
  if (!assetsLoaded) return;
  setCashoutAmount(num1, num2, currencySymbol);
}

export function setXaxis(num) {
  divideOnXaxis(num);
}

export function CenterText(num) {
  if (!assetsLoaded) return;
  setCenterText(num);
}

export function gettingSignal() {
  if (!assetsLoaded) return;
  getSignal();
}

export function changeStatusOfSignal() {
  if (!assetsLoaded) return;
  changeSignalStatus("online");
}

export function setUserCashOutAmount(
  totalPlayer = 0,
  playerCashoutPercentage = 0
) {
  if (!assetsLoaded) return;
}
