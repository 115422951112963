import { rollerCoaster } from 'games/CryptoGame/components/Icons/png'

export const DEFAULT_PRECISION_MONEY = 2

export const formatDate = (originalDateString) => {
  const date = new Date(originalDateString)

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  const formattedDate = `${day} ${months[monthIndex]} ${year}`
  return formattedDate
}

export const formatTime = (originalDateString) => {
  const date = new Date(originalDateString)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`

  return formattedTime
}

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

/**
 *
 * @param {Number} n
 * @returns {Number} factorial of n
 */
export const factorial = n => {
  let fact = 1
  for (let i = 2; i <= n; i++) {
    fact *= i
  }
  return fact
}

/**
 *
 * @param {Number} n
 * @param {Number} r
 * @returns {Number} combination of n over r
 */
export const combination = (n, r) => {
  if (r > n) {
    return 0
  }
  return factorial(n) / (factorial(r) * factorial(n - r))
}

/**
 *
 * @param {Number} numberOfCoins
 * @param {Number} minimumChosenOutcome
 * @returns {Number} probability of minimum X number of favorable outcomes
 */
export const getCoinOutcomeProbability = (numberOfCoins, minimumChosenOutcome) => {
  const n = numberOfCoins - minimumChosenOutcome
  let sum = 0
  for (let i = 0; i <= n; i++) {
    sum += combination(numberOfCoins, i)
  }
  return sum / 2 ** numberOfCoins
}

/**
 * To calculate flip coin potential win
 * @param {number} betAmount
 * @param {number} numberOfCoins
 * @param {number} minimumChosenOutcome
 * @param {number} houseEdge
 * @returns {number}
 */
export const getFlipCoinPotentialWin = (betAmount, numberOfCoins, minimumChosenOutcome, houseEdge) => {
  const probability = getCoinOutcomeProbability(numberOfCoins, minimumChosenOutcome)
  const odds = (1 / probability) * (1 - houseEdge / 100)
  return +Number((betAmount * odds)).toFixed(2)
}

/**
 *
 * @param {string} s
 * @returns {Number} count of one
 */
export const countOnes = (s) => {
  let count = 0
  for (const i of s) {
    if (i === '1') {
      count++
    }
  }
  return count
}

/**
 *
 * @param  {...Array} a Specify arrays to do cartesian product
 * @returns {Array.<Array.<number[]>>} Returns the cartesian product of given arrays
 */
export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

/**
 *
 * @param {number} value
 * @param {number} precision
 * @returns {number | string} Returns precision value for specified decimal places
 */
export const getPrecision = (value, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(value * precisionDivide) / precisionDivide
  return result || 0
}

/**
 * To convert a numeric value to standard precision
 * @param {number} amount amount to be converted to precision
 * @param {number} precision digits to be considered for precision
 * @returns {number}
 */
export const getPrecisionNumber = (amount, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(amount * precisionDivide) / precisionDivide || 0
  return result
}

/**
 *
 * @param {number} x
 * @returns {string} valid million based comma value in string
 */
export const numberWithCommas = (x) => {
  if (x === null || x === undefined || Number(x) === Number.isNaN) {
    return ''
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Get Random Integer from min to max (both inclusive)
 * @param {*} max
 * @returns
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max + 1)
  return Math.floor(Math.random() * (max - min) + min)
}
/**
 * Durstenfeld array shuffling algorithm
 * @param {number} array
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  }
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export const startTime = (time) => {
  return moment().startOf(time).format()
}

export const endTime = (time) => {
  return moment().endOf(time).format()
}

export const cryptoIcon = (id) => {
  switch (id) {
    case '1':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'
    case '2':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
    case '3':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png'
    case '4':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png'
    case '5':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png'
    case '6':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png'
    case '7':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png'
    case '8':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/16116.png'
    case '9':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png'
    case '10':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png'
    case '11':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png'
    case '12':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png'
    case '13':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png'
    case '14':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png'
    case '15':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/18876.png'
    case '16':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/21794.png'
    case '17':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png'
    case '18':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png'
    case '19':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png'
    case '20':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png'
    case '21':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png'
    case '22':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/13502.png'
    case '23':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png'
    case '24':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/28301.png'
    case '25':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png'
    case '26':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/20396.png'
    case '27':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23121.png'
    case '28':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/28177.png'
    case '29':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23095.png'
    case '30':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/23149.png'
    case '31':
      return 'https://s2.coinmarketcap.com/static/img/coins/64x64/22861.png'
    default:
      return rollerCoaster
  }
}

/**
 * 
 * @param {String} input 
 * @returns 
 * 
 * input- "SAMPLE_INPUT" 
 * output- "Sample Output"
 */
export const formatString = (input) => {
  const words = input.toLowerCase().split('_');
  const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return formattedWords.join(' ');
}