import { createSlice } from '@reduxjs/toolkit'
import { diceGamePlacedBet, getDiceGameBetRecord } from './diceGame.thunk'

const initialState = {
  loading: false,
  diceBetHistory: null,
  betResult: null,
  wins: 0,
  losses: 0,
  profit: 0,
  wagered: 0,
  showDiceHistory: false,
  betHistoryData: null,
  betLock: false
}

const {
  actions: {
    setDiceBetHistory,
    resetDiceGameResult,
    setDiceLossCount,
    setDiceWinsCount,
    setProfit,
    setWagered,
    setBetHistoryData
  },
  reducer
} = createSlice({
  name: 'diceGame',
  initialState,
  reducers: {
    setBetHistoryData: (state, action) => ({
      ...state,
      betHistoryData: action.payload,
      showDiceHistory: !!action.payload
    }),
    setDiceBetHistory: (state, action) => ({
      ...state,
      diceBetHistory: action?.payload
    }),
    resetDiceGameResult: (state, action) => ({
      ...state,
      betResult: null
    }),
    setDiceWinsCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          wins: action.payload.reset
        }
      }
      return {
        ...state,
        wins: state.wins + 1
      }
    },
    setDiceLossCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          losses: action.payload.reset
        }
      }
      return {
        ...state,
        losses: state.losses + 1
      }
    },
    setProfit: (state, action) => {
      return {
        ...state,
        profit: action.payload
      }
    },
    setWagered: (state, action) => {
      return {
        ...state,
        wagered: action.payload
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(diceGamePlacedBet.pending, (state, action) => {
        return {
          ...state,
          loading: true,
          betLock: true
        }
      })
      .addCase(diceGamePlacedBet.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          betResult: action?.payload,
          betLock: false
        }
      })
      .addCase(diceGamePlacedBet.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          betLock: false
        }
      })
      .addCase(getDiceGameBetRecord.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getDiceGameBetRecord.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          diceBetHistory: action?.payload
        }
      })
      .addCase(getDiceGameBetRecord.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export {
  setDiceBetHistory,
  resetDiceGameResult,
  setDiceWinsCount,
  setDiceLossCount,
  setProfit,
  setWagered,
  setBetHistoryData
}

export default reducer
