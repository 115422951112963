import * as PIXI from "pixi.js";
import * as UI from "./gameUI";
import { app } from "./appPixi";
import { settings } from "./settings";
import { createCashOut } from "./gameManager";
import { themes } from "./assetsLoad";

let boolLaunch = false;
let timeUp = false;
let isloading = false;

let currentWidth = 0;
let targetWidth = 0;
let loaderSpeed = 10;

const appWidth = app.screen.width;
const appHeight = app.screen.height;

const appAspectRatio = app.screen.width / app.screen.height;

const appAspectRatioX = appWidth / 1920;
const appAspectRatioY = appHeight / 1080;

let playerPosition = {
  x: app.screen.width * settings.axisGapX,
  y: app.screen.height * settings.axisGapY,
};
let speed = settings.speed * appAspectRatioX;

let maxWidth = app.screen.width * 0.9;
let maxHeight = app.screen.height * 0.17;

let loaderCounter = 0;
let copyLoaderWidth = 0;

let xCurveFactor = app.screen.width * settings.axisGapX;
let yCurveFactor = app.screen.height * settings.axisGapY;

let cacheTopGraphColor = settings.topGraphColor;
let cacheBottonGraphColor = settings.bottomGraphColor;

let beepSignalWidth = 0;

let currentSignalColor = 0x97a1a8;

let boolIsCashoutActive = false;

let timer = 0;

let graphPath = [];

let currentIndex = 0;

let startPoint = {
  x: app.screen.width * settings.axisGapX,
  y: app.screen.height * settings.axisGapY,
};
let endPoint = {
  x: maxWidth,
  y: maxHeight,
};

let controlPoint = {
  x: (endPoint.x - startPoint.x) * 0.7,
  y: (startPoint.y - endPoint.y) * 1.1,
};

let stopPlayerMovement = false;

let widthExtender = 0;

function loadGame(time) {
  copyLoaderWidth = UI.loaderWidth;
  UI.gameAssets.loaderFront.clear();
  UI.gameAssets.loaderFront.lineStyle(0, 0x8953fc, 1);
  UI.gameAssets.loaderFront.beginFill(0x8953fc, 1);
  UI.gameAssets.loaderFront.drawRoundedRect(
    -UI.loaderWidth,
    -UI.loaderHeight,
    copyLoaderWidth,
    UI.loaderHeight,
    5
  );
  UI.gameAssets.loaderFront.endFill();
  UI.containers.loadingContainer.visible = true;
  UI.containers.centerTextContainer.visible = false;

  loaderCounter = UI.loaderWidth / time;
  isloading = true;
  copyLoaderWidth = UI.loaderWidth;
  timer = time * 60;
}

function startGame() {
  boolLaunch = true;
  copyLoaderWidth = 0;
  UI.containers.loadingContainer.visible = false;
  UI.containers.centerTextContainer.visible = true;
}

function crashGame(num) {
  boolLaunch = false;

  UI.gameAssets.centerBigText.text = num + "x";
  UI.gameAssets.centerBigTextBG.texture =
    PIXI.Assets.get("completeAssets").textures.blastpopup;
  UI.gameAssets.centerBigTextBG.scale.set(
    appAspectRatioX * 0.7,
    appAspectRatioX * 0.7
  );

  if (!boolIsCashoutActive) {
    UI.gameAssets.centerBigText.position.set(
      0,
      -UI.gameAssets.centerBigText.height * 0.3
    );
    UI.gameAssets.crashCenterText.position.set(
      0,
      UI.gameAssets.centerBigText.y + UI.gameAssets.crashCenterText.height * 1.5
    );
    UI.gameAssets.crashCenterText.visible = true;
  }

  UI.gameAssets.player.visible = false
  UI.gameAssets.playerCrashed.visible = true
  // UI.gameAssets.player.texture =
  //   PIXI.Assets.get("completeAssets").textures.blast;

  UI.gameAssets.topLineMask.visible = false;
  UI.gameAssets.bottomLineMask.visible = false;

  UI.gameAssets.topLineMask.mask = null;
  UI.gameAssets.topLineMask.visible = false;

  UI.gameAssets.topLineGraph.tint = settings.themeColors[themes.currentTheme].crashTop;
  UI.gameAssets.bottomGraph.tint = settings.themeColors[themes.currentTheme].crashBgFill;
}

function setValuesToInitial() {
  controlPoint = {
    x: (endPoint.x - startPoint.x) * 0.7,
    y: (startPoint.y - endPoint.y) * 1.1,
  };

  boolLaunch = false;
  isloading = false;
  widthExtender = 0;

  stopPlayerMovement = false;

  speed = settings.speed * appAspectRatioX;

  boolIsCashoutActive = false;

  currentWidth = 0;
  targetWidth = 0;

  UI.gameAssets.loaderPercent.visible = false;

  UI.gameAssets.centerBigTextBG.texture =
    PIXI.Assets.get("completeAssets").textures.popup;
  UI.gameAssets.centerBigTextBG.scale.set(
    appAspectRatioX * 0.7,
    appAspectRatioX * 0.6
  );
  UI.gameAssets.centerBigText.position.set(0, 0);

  UI.gameAssets.crashCenterText.visible = false;

  UI.gameAssets.loaderFront.clear();
  UI.gameAssets.loaderFront.lineStyle(0, 0x8953fc, 1);
  UI.gameAssets.loaderFront.beginFill(0x8953fc, 1);
  UI.gameAssets.loaderFront.drawRoundedRect(
    -UI.loaderWidth,
    -UI.loaderHeight,
    0,
    UI.loaderHeight,
    5
  );
  UI.gameAssets.loaderFront.endFill();

  UI.containers.playerContainer.position.set(
    app.screen.width * settings.axisGapX,
    app.screen.height * settings.axisGapY
  );

  xCurveFactor = app.screen.width * settings.axisGapX;
  yCurveFactor = app.screen.height * settings.axisGapY;

  UI.gameAssets.bottomGraph.clear();
  UI.gameAssets.graphMask.clear();

  cacheTopGraphColor = settings.topGraphColor;
  cacheBottonGraphColor = settings.bottomGraphColor;

  UI.gameAssets.LineGraphInitialCurve.clear();
  UI.gameAssets.LineGraphInitialCurve.beginFill(settings.topGraphColor, 1);
  UI.gameAssets.LineGraphInitialCurve.drawCircle(0, 0, 10 * appAspectRatioX);
  UI.gameAssets.LineGraphInitialCurve.endFill();

  divideOnXaxis(15);
  divideOnYaxis(3.2);

  UI.gameAssets.bottomGraph.beginFill(settings.bottomGraphColor, 1);

  UI.containers.cashedOutContainer.visible = false;

  UI.containers.crashedContainer.visible = false;
  UI.containers.payOutContainer.visible = true;

  UI.gameAssets.player.visible = true
  UI.gameAssets.playerCrashed.visible = false
  // UI.gameAssets.player.texture =
  //   PIXI.Assets.get("completeAssets").textures.rocket;

  UI.gameAssets.topLineMask.visible = true;
  UI.gameAssets.bottomLineMask.visible = true;

  UI.gameAssets.centerBigText.text = "1.0x";

  getpath();

  UI.containers.playerContainer.rotation = 0.8;
  UI.gameAssets.player.position.set(
    40 * appAspectRatioX,
    -40 * appAspectRatioX
  );

  UI.gameAssets.cashedOutText.visible = false;
  UI.gameAssets.multiplierText.visible = false;
  UI.gameAssets.amountText.visible = false;
  UI.gameAssets.dollar.visible = false;

  UI.gameAssets.topLineMask.visible = true;
  // UI.gameAssets.topLineMask.mask = UI.gameAssets.topLineGraph;

  UI.gameAssets.background[0].position.set(
    app.screen.width * 0.5,
    app.screen.height * 0.5
  );

  UI.gameAssets.background[1].position.set(
    app.screen.width * 0.5,
    app.screen.height * 0.5 + UI.gameAssets.background[1].height
  );

  UI.gameAssets.background[2].position.set(
    app.screen.width * 0.5 + UI.gameAssets.background[2].width*1.04,
    app.screen.height * 0.5
  );

  UI.gameAssets.background[3].position.set(
    app.screen.width * 0.5 + UI.gameAssets.background[3].width * 1.04,
    app.screen.height * 0.5 + UI.gameAssets.background[3].height
  );
  // for (let index = 0; index < UI.gameAssets.background.length; index++) {
  // }
}

function divideOnXaxis(num) {
  let Div = num / UI.numOfXaxisDivision;
  for (let index = 0; index < UI.gameAssets.xAxis.length; index++) {
    UI.gameAssets.xAxis[index].text = (Div * index).toFixed(0) + "s";
    UI.gameAssets.xAxis[index].tint = "0xAFAFAF";
  }
}


function divideOnYaxis(num) {
  let scaleText
  let axisGap

  if(num < 100){
    scaleText = 0.4
    axisGap = 0.7
  }else if(num >= 100 && num < 1000){
    scaleText = 0.36
    axisGap = 0.6
  }else{
    scaleText = 0.3
    axisGap = 0.6
  }

  num = num - 1;
  let Div = num / UI.numOfYaxisDivision;
  for (let index = 0; index < UI.gameAssets.yAxis.length; index++) {
    if (index == 0) UI.gameAssets.yAxis[index].text = "1.0x";
    else UI.gameAssets.yAxis[index].text = (1 + Div * index).toFixed(1) + "x";

    UI.gameAssets.yAxis[index].scale.set(scaleText)

    UI.gameAssets.yAxis[index].x =
      appWidth * settings.axisGapX - UI.gameAssets.yAxis[index].width * axisGap;
    UI.gameAssets.yAxis[index].tint = "0xAFAFAF";
  }
}

function setCenterText(num) {
  if (num.length > 5) {
    UI.gameAssets.centerBigText.scale.set(appAspectRatioX * 0.7);
  } else {
    UI.gameAssets.centerBigText.scale.set(appAspectRatioX * 1);
  }

  UI.gameAssets.centerBigText.text = num + "x";
  if (num > 3.2) {
    divideOnYaxis(num);
  }
}

function setCashoutAmount(num1, num2, currencySymbol) {

  if (!boolLaunch) return;
  boolIsCashoutActive = true;
  UI.gameAssets.cashedOutText.visible = true;
  UI.gameAssets.multiplierText.visible = true;
  UI.gameAssets.amountText.visible = true;
  UI.gameAssets.dollar.visible = true;
  UI.gameAssets.centerBigTextBG.scale.set(
    appAspectRatioX * 0.7,
    appAspectRatioX * 0.7
  );
  UI.gameAssets.centerBigText.position.set(
    0,
    -UI.gameAssets.centerBigText.height * 0.4
  );
  UI.gameAssets.multiplierText.text = num1 + "x";
  UI.gameAssets.amountText.text = num2;
  UI.gameAssets.cashedOutText.position.set(
    -UI.gameAssets.cashedOutText.width * 0.25,
    UI.gameAssets.centerBigText.y + UI.gameAssets.cashedOutText.height * 2
  );
  UI.gameAssets.multiplierText.position.set(
    UI.gameAssets.cashedOutText.position.x +
      UI.gameAssets.cashedOutText.width * 0.5 +
      UI.gameAssets.multiplierText.width * 0.6,
    UI.gameAssets.centerBigText.y + UI.gameAssets.cashedOutText.height * 2
  );
  UI.gameAssets.dollar.position.set(
    -UI.gameAssets.cashedOutText.width * 0.3,
    UI.gameAssets.cashedOutText.y + UI.gameAssets.amountText.height * 1.5
  );
  UI.gameAssets.amountText.position.set(
    UI.gameAssets.dollar.position.x +
      UI.gameAssets.dollar.width +
      UI.gameAssets.amountText.width * 0.9,
    UI.gameAssets.cashedOutText.y + UI.gameAssets.amountText.height * 1.5
  );

  switch (currencySymbol) {
    case 'USD':
      UI.gameAssets.dollar.visible = true
      UI.gameAssets.dollar.texture = PIXI.Assets.get("usd")

      break;

    case 'EUR':
    UI.gameAssets.dollar.visible = true
    UI.gameAssets.dollar.texture = PIXI.Assets.get("eur")

      break;


    default:
      UI.gameAssets.dollar.visible = false
      UI.gameAssets.dollar.texture = PIXI.Assets.get("usd")
      break;
  }

}

function setUserCashoutPercentage(num, percent) {
  UI.gameAssets.loadingText.text = percent + "% of players cashed out";
  UI.gameAssets.loadingText.position.set(
    appWidth * 0.9 - UI.gameAssets.loadingText.width * 0.5,
    UI.gameAssets.loadingText.height
  );

  targetWidth = (UI.loaderWidth * percent) / 100;

  if (targetWidth > 0) {
    UI.gameAssets.loaderPercent.visible = true;
  }

  UI.gameAssets.playerCount.text = "Players: " + num;
}

function quadraticBezier(p0, p1, p2, t) {
  let x =
    Math.pow(1 - t, 2) * p0.x + 2 * (1 - t) * t * p1.x + Math.pow(t, 2) * p2.x;
  let y =
    Math.pow(1 - t, 2) * p0.y + 2 * (1 - t) * t * p1.y + Math.pow(t, 2) * p2.y;
  return {
    x: x,
    y: y,
  };
}

function getpath() {
  currentIndex = 1;
  graphPath = [];

  UI.gameAssets.topLineGraph.clear();
  UI.gameAssets.topLineGraph.lineStyle(15 * UI.appAspectRatioX, 0xffffff);
  UI.gameAssets.topLineGraph.tint = settings.themeColors[themes.currentTheme].topGraph
  UI.gameAssets.topLineGraph.moveTo(
    app.screen.width * settings.axisGapX,
    app.screen.height * settings.axisGapY
  );

  UI.gameAssets.bottomGraph.clear();
  UI.gameAssets.bottomGraph.lineStyle(0);
  UI.gameAssets.bottomGraph.beginFill(0xffffff, 0.2);
  UI.gameAssets.bottomGraph.tint = settings.themeColors[themes.currentTheme].bgFill
  UI.gameAssets.bottomGraph.moveTo(
    app.screen.width * settings.axisGapX,
    app.screen.height * settings.axisGapY
  );

  for (let t = 0; t <= 1; t += 0.01) {
    let pointOnCurve = quadraticBezier(startPoint, controlPoint, endPoint, t);
    UI.gameAssets.topLineGraph.lineTo(pointOnCurve.x, pointOnCurve.y);
    UI.gameAssets.bottomGraph.lineTo(pointOnCurve.x, pointOnCurve.y);
    graphPath.push(pointOnCurve);
  }
  UI.gameAssets.bottomGraph.lineTo(
    graphPath[graphPath.length - 1].x,
    app.screen.height * settings.axisGapY
  );
  UI.gameAssets.bottomGraph.lineTo(
    app.screen.width * settings.axisGapX,
    app.screen.height * settings.axisGapY
  );
  UI.gameAssets.bottomGraph.closePath();
  UI.gameAssets.bottomGraph.endFill();

  // UI.gameAssets.topLineGraph.tint = settings.themeColors[themes.currentTheme].topGraph
  // UI.gameAssets.bottomGraph.tint = settings.themeColors[themes.currentTheme].bgFill
}

export function distance(currentPos, targetPos) {
  let distance =
    Math.pow(targetPos.x - currentPos.x, 2) +
    Math.pow(targetPos.y - currentPos.y, 2);
  distance = Math.sqrt(distance);
  return distance;
}

export function moveTowards(currentPos, targetPos, speed) {
  let xDirection = targetPos.x - currentPos.x;
  let yDirection = targetPos.y - currentPos.y;
  currentPos.x += xDirection * speed;
  currentPos.y += yDirection * speed;
  return currentPos;
}

//Graph ticker
app.ticker.add((delta) => {
  beepSignal(delta);
  if (!boolLaunch) return;

  defineGraph(delta);
  copyLoaderWidth = 0;
});

//loader ticker
app.ticker.add((delta) => {
  if (!isloading) return;
  if (timer > 0) {
    UI.gameAssets.loadingText.visible = true;
    timer -= delta;
    let revTimer = (timer.toFixed(1) / 60).toFixed(1);
    UI.gameAssets.loadingText.text = "Starts in " + Math.max(0, revTimer) + "s";
    // }
  } else {
    isloading = false;
    UI.containers.loadingContainer.visible = false;
  }

  UI.gameAssets.loaderFront.clear();
  UI.gameAssets.loaderFront.lineStyle(0, 0x8953fc, 1);
  UI.gameAssets.loaderFront.beginFill(0x8953fc, 1);
  UI.gameAssets.loaderFront.drawRoundedRect(
    -UI.loaderWidth,
    -UI.loaderHeight,
    copyLoaderWidth,
    UI.loaderHeight,
    5
  );
  UI.gameAssets.loaderFront.endFill();
});

function speedCalculator(speedReductor) {
  if (speed > 0) speed -= speedReductor;
}

let floatNum = 0
function defineGraph(delta) {
  if (currentIndex < graphPath.length && !stopPlayerMovement) {
    if (distance(UI.containers.playerContainer.position, graphPath[currentIndex]) < 20) {
      currentIndex++;
      if (currentIndex > graphPath.length * 0.7) speedCalculator(0.0012);
    }
    else {
      UI.containers.playerContainer.position = moveTowards(
        UI.containers.playerContainer.position,
        graphPath[currentIndex],
        speed
      );
      UI.containers.playerContainer.rotation =
        0.8 +
        (maxWidth - UI.containers.playerContainer.position.x) / maxWidth -
        0.97;
    }
  } else {
    stopPlayerMovement = true;
    floatNum += delta * 0.02
    UI.containers.playerContainer.position.x -= parseFloat(Math.sin(floatNum).toFixed(2)) * 0.2
    UI.containers.playerContainer.position.y += parseFloat(Math.sin(floatNum).toFixed(2)) * 0.2
    if (widthExtender < 50) widthExtender += 0.2;
    if (controlPoint.x < endPoint.x) controlPoint.x += 0.2 * appAspectRatioX;
    // if (controlPoint.y < startPoint.y) controlPoint.y += 0.2 * appAspectRatioY;
    endPoint.x = UI.containers.playerContainer.position.x
    endPoint.y = UI.containers.playerContainer.position.y

    getpath();
/*
    UI.containers.playerContainer.rotation = Math.max(
      -0.8,
      UI.containers.playerContainer.rotation - 0.0003 * delta
    ); // math.min()  // ((maxWidth- UI.containers.playerContainer.position.x)/maxWidth)
    UI.gameAssets.player.position.x = Math.min(
      30,
      UI.gameAssets.player.position.x + 0.008
    );
    */
  }

  for (let index = 0; index < UI.gameAssets.background.length; index++) {
    const element = UI.gameAssets.background[index];

    element.x -= delta ;
    element.y += delta;
    if (element.x < -element.width * 0.5) element.x = app.screen.width * 1.3;
    if (element.y > element.height * 1.5) element.y = -app.screen.height * 0.5;
  }

  UI.gameAssets.graphMask.clear();
  UI.gameAssets.graphMask.beginFill(settings.blue, 0.001);
  UI.gameAssets.graphMask.drawRoundedRect(
    0,
    0,
    UI.containers.playerContainer.position.x -
      appWidth * settings.axisGapX * 0.1 +
      widthExtender,
    appHeight,
    5
  );
  UI.gameAssets.graphMask.endFill();
}

function beepSignal(delta) {
  if (beepSignalWidth > 40) {
    beepSignalWidth = -1;
  } else if (beepSignalWidth >= 0) {
    beepSignalWidth += 5 * delta;
  }

  UI.gameAssets.networkSignal.clear();
  UI.gameAssets.networkSignal.lineStyle(
    beepSignalWidth,
    currentSignalColor,
    0.5
  );
  UI.gameAssets.networkSignal.beginFill(currentSignalColor, 1);
  UI.gameAssets.networkSignal.drawCircle(
    -UI.gameAssets.networkStatusText.width * 0.63,
    UI.gameAssets.networkStatusText.height * 0.3,
    20
  );
  UI.gameAssets.networkSignal.endFill();
}

function getSignal() {
  beepSignalWidth = 0;
}

function changeSignalStatus(status) {
  //online or offline
  if (status === "online") {
    currentSignalColor = "0x00E349";
  } else {
    currentSignalColor = "0xF10260";
  }
}

export {
  startGame,
  setValuesToInitial,
  crashGame,
  loadGame,
  divideOnXaxis,
  divideOnYaxis,
  setCenterText,
  changeSignalStatus,
  getSignal,
  setCashoutAmount,
  setUserCashoutPercentage,
};
