import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cancelCrashBetService,
  escapeCrashBetService,
  getAllRoundHistoryService,
  getMyBetsService,
  verifyProvableFairCrashService,
  getTopBetsService,
  placeBetCrashGameService,
  getPreviousRoundBetsService,
  getCrashCurrentPlacedBetService,
  allPlacedBetsInRoundCrashGameService,
} from "games/CrashGame/crashGame.service";
import { createCashOut } from 'games/CrashGame/pixi-js-scripts/gameManager'
import { appendCurrentBetsToMyBets, setCurrentPlacedBet, setPlacedCrashBetId, setPlacingOrCashoutLoading } from 'games/CrashGame/slice-thunk/crashGame.slice'
import { APPEND_TYPES } from '../crashgame.constants'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { BET_RESULT } from "constants/index";

/**
 * getCrashCurrentPlacedBet fetches the details of previously unfinished bets
 * i.e User places a bet but accidentally refreshed the page
 */
export const getCrashCurrentPlacedBet = createAsyncThunk(
  'crash-game/current-bet', async ({ handleBtnStatesForCurrentBetSuccess }, thunkApi) => {
    const id = 0 // index value of in placedCrashBetId
    try {
      const res = await getCrashCurrentPlacedBetService()
      
      if(res && res.hasUnfinishedGameBet){
        const unfinishedGameBetDetails = res.unfinishedGameBetDetails[0]
        if(unfinishedGameBetDetails.result === BET_RESULT.PENDING){
        thunkApi.dispatch(getCrashAllPlacedBetInRound({ roundId: unfinishedGameBetDetails.roundId }))
        thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: true }))
        
        openSuccessToaster({message: 'Fetched Previous Bet'})
        handleBtnStatesForCurrentBetSuccess()
        thunkApi.dispatch(setPlacedCrashBetId({ id, betId: unfinishedGameBetDetails.id, reset: false }))
        thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: unfinishedGameBetDetails, reset: false }))
        thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
      }
      }
      return res
    } catch (error) {
      console.log("error ", error)
      thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

/**
 * getCrashAllPlacedBetInRound fetches the details of placed bets in a given round id
 */
export const getCrashAllPlacedBetInRound = createAsyncThunk(
  'crash-game/all-placed-bet-in-round', async (params, thunkApi) => {
    try {
      const res = await allPlacedBetsInRoundCrashGameService(params)
      return res
    } catch (error) {
      console.log("error ", error)
      thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)



export const placedBetCrashGame = createAsyncThunk('crash-game/place-bet-crash-game', async ({ payload, id, onSuccess }, thunkApi) => {
  try {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: true }))
    const res = await placeBetCrashGameService(payload)
    openSuccessToaster({message: 'Bet Placed Successfully'})
    onSuccess()
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: res.id, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: res, reset: false }))
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return res
  } catch (error) {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const escapeCrashBet = createAsyncThunk('crash-game/player-escape-crashGame', async ({ betId, id, onSuccess }, thunkApi) => {
  try {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: true }))
    const res = await escapeCrashBetService({ betId })
    onSuccess()
    // const store = thunkApi.getState()
    // if (store?.crashGame?.myBetsData) {
    //   const state = store?.crashGame
    //   const escapedBet = [res]
    //   const betExist = state?.myBetsData?.rows?.some(bet => bet?.id === escapedBet?.[0]?.id)
    //   if (!betExist) {
    //     createCashOut((betExist?.escapeRate).toFixed(2))
    //   }
    // }
    thunkApi.dispatch(appendCurrentBetsToMyBets({ type: APPEND_TYPES.ESCAPE, data: [res] }))
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: null, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: null, reset: false }))
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return res
  } catch (error) {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cancelCrashBet = createAsyncThunk('crash-game/cancel-bet-crash-game', async ({ id, betId }, thunkApi) => {
  try {
    const res = await cancelCrashBetService({ betId })
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: null, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: null, reset: false }))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getAllRoundHistory = createAsyncThunk('crash-game/get-crash-game-history', async (_, thunkApi) => {
  try {
    const res = await getAllRoundHistoryService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBets = createAsyncThunk('crash-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsPaginated = createAsyncThunk('crash-game/myBets-paginated', async (params, thunkApi) => {
  try {
    const res = await getMyBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getPreviousRoundBets = createAsyncThunk('crash-game/previous-round-placed-bets', async (params, thunkApi) => {
  try {
    const res = await getPreviousRoundBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const verifyProvableFairCrash = createAsyncThunk('crash-game/check-provable-fair', async ({ payload }, thunkApi) => {
  try {
    const res = await verifyProvableFairCrashService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const getTopBets = createAsyncThunk('crash-game/topBets', async (params, thunkApi) => {
  try {
    const res = await getTopBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
