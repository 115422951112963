import { settings } from "./settings";
import * as PIXI from "pixi.js";

export const app = new PIXI.Application({
  width: settings.originalWidth,
  height: settings.originalHeight,
  antialias: true,
  backgroundColor: 0xffffff,
  backgroundAlpha: 0,
  resolution: window.devicePixelRatio,
});
globalThis.__PIXI_APP__ = app;
app.ticker.maxFPS = 60;
app.ticker.minFPS = 50;

function appTransform(x, y) {
  app.renderer.resize(x, y);
}

appTransform(727, 591);
