import { createSlice } from '@reduxjs/toolkit'
import { demoPlacedBetPlinkoGame, getMyBetsPlinko, placedBetPlinkoGame, postLightningBoardDetails } from 'games/PlinkoGame/slice-thunk/plinkoGame.thunk'
import { DEFAULT_PAGE_CALLS, DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from '../plinkogame.constants'
import { BET_RESULT } from 'constants/index'
import { plus, minus } from 'number-precision'

const initialState = {
  placedBetData: null,
  placedBets: [],
  betLock: false,
  betRespLock: false,
  apiHitCount: 0,
  betLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  wins: 0,
  losses: 0,
  profit: 0,
  wagered: 0,
  myBetsLoading: false,
  lightningBoardLoading: false,
  lightningBoardBallMultipliers: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  lightningBoardPayouts: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
}

const {
  actions: {
    setBetLock,
    setLightningBoardDetails,
    appendMyBetsPlinko,
    setPlinkoLossCount,
    setPlinkoWinsCount,
    setPlinkoProfit,
    setPlinkoWagered
  },
  reducer
} = createSlice({
  name: 'plinkoGame',
  initialState,
  reducers: {
    setPlinkoWinsCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          wins: action.payload.reset
        }
      }
      return {
        ...state,
        wins: state.wins + 1
      }
    },
    setPlinkoLossCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          losses: action.payload.reset
        }
      }
      return {
        ...state,
        losses: state.losses + 1
      }
    },
    setPlinkoProfit: (state, action) => {
      return {
        ...state,
        profit: action.payload
      }
    },
    setPlinkoWagered: (state, action) => {
      return {
        ...state,
        wagered: action.payload
      }
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: action.payload
      }
    },
    setLightningBoardDetails: (state, action) => {
      return {
        ...state,
        lightningBoardBallMultipliers: action.payload?.lightningBoardBallMultipliers ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
        lightningBoardPayouts: action.payload?.lightningBoardPayouts ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
      }
    },
    appendMyBetsPlinko: (state, action) => {
      let _placedBets = [...(state?.placedBets ?? [])]
      const _betToAppend = _placedBets?.[_placedBets.length - 1]
      _placedBets = _placedBets?.slice(0, _placedBets.length - 1)
      const { nextServerSeedHash, ...data } = _betToAppend || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...(state?.myBetsData?.rows ?? [])]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        const profit = plus(state.profit, minus(_betToAppend?.winningAmount ?? 0, _betToAppend?.betAmount ?? 0))
        const wagered = plus(state.wagered, _betToAppend?.betAmount)
        const wins = plus(state.wins, _betToAppend?.result === BET_RESULT.WON ? 1 : 0)
        const losses = plus(state.losses, _betToAppend?.result === BET_RESULT.LOST ? 1 : 0)
        return {
          ...state,
          placedBets: _placedBets,
          ...(_placedBets?.length === 0
            ? {
              betLock: false
            }
            : {}),
          profit,
          wagered,
          wins,
          losses,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betRespLock: true,
          betLoading: true,
          apiHitCount: state.apiHitCount + 1
        }
      })
      .addCase(placedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBets: [action.payload, ...(state?.placedBets ?? [])],
          placedBetData: action.payload,
          betLoading: false,
          apiHitCount: state.apiHitCount - 1,
          betRespLock: (state.apiHitCount > 1)
        }
      })
      .addCase(placedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          apiHitCount: state.apiHitCount - 1,
          betRespLock: (state.apiHitCount > 1),
          betLoading: false
        }
      })
      .addCase(demoPlacedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true
        }
      })
      .addCase(demoPlacedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBets: [action.payload, ...(state?.placedBets ?? [])],
          placedBetData: action.payload,
          betLoading: false
        }
      })
      .addCase(demoPlacedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsPlinko.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true

        }
      })
      .addCase(getMyBetsPlinko.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
      .addCase(postLightningBoardDetails.pending, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: true

        }
      })
      .addCase(postLightningBoardDetails.fulfilled, (state, action) => {
        const lightningBoardDetails = action.payload?.lightningBoardDetails
        return {
          ...state,
          lightningBoardBallMultipliers: lightningBoardDetails?.betMultipliers,
          lightningBoardPayouts: lightningBoardDetails?.payouts,
          lightningBoardLoading: false
        }
      })
      .addCase(postLightningBoardDetails.rejected, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: false
        }
      })
  }
})

export {
  setBetLock,
  setLightningBoardDetails,
  appendMyBetsPlinko,
  setPlinkoLossCount,
  setPlinkoWinsCount,
  setPlinkoProfit,
  setPlinkoWagered
}

export default reducer
