import { createSlice } from '@reduxjs/toolkit'
import { mineGamePlacedBet, mineGameAutoBet, getMyBetsMineGame, mineGameOpenTile, mineGameCashOut, getMineGamePreviousRoundState, minGameTopBets, getMineGameLiveStats } from 'games/MineGame/slice-thunk/mineGame.thunk'
import { minus, plus } from 'number-precision'
import pickBy from 'lodash/pickBy'
import { getPrecision, getPrecisionNumber } from 'helpers/common.helpers'
import { BET_RESULT } from 'constants/index'
import { DEFAULT_MINE_GAME_AUTOBET_ROUNDS } from '../mineGame.constants'
import { calculateMineGameCashoutOdds, generateRandomResult } from '../game-helpers/index'

const initialState = {
  loading: false,
  currentPlacedBet: null,
  currentSelectedTiles: [],
  betLock: false,
  gameEnd: false,
  myBetsDataLoading: false,
  myBetsData: [],
  topBetsData: [],
  autoBetNoOfRounds: `${DEFAULT_MINE_GAME_AUTOBET_ROUNDS}`,
  autoBetCurrentPlacedBet: null,
  isAutoPlayStarted: false,
  autoBetSuccess: false,
  strategyInputs: {
    stopOnProfit: 0,
    stopOnLoss: 0,
    maxBetAmount: 0,
    onWinEnabled: false,
    onWinIncreasePercent: 0,
    onLossEnabled: false,
    onLossIncreasePercent: 0
  },
  strategyWatchers: {
    totalBetAmount: 0,
    autoBetAmount: 0,
    netReturn: 0
  },
  isLastWin: false,
  isLastLoss: false,
  liveStates: {
    profit: 0,
    wagered: 0,
    wins: 0,
    losses: 0
  },
  minesVolume: 1,
  demoMineResult: null,
}

const {
  actions: {
    setAutoBetNoOfRounds,
    addCurrentSelectedTiles,
    removeCurrentSelectedTiles,
    callMineGameRoundEnd,
    stopAutoBet,
    setAutoPlayEndAfterAnimation,
    setStrategyInputs,
    setAutoBetAmount,
    setRoundResult,
    setLiveStats,
    setMinesVolume,
    resetLiveStates,
    demoMinePlaceBet,
    demoMineAutoPlaceBet,
    demoMineAutoBetPending,
    demoMineCancelBet,
    demoMineGameOpenTile,
    demoMineGameCashOut
  },
  reducer
} = createSlice({
  name: 'mineGame',
  initialState,
  reducers: {
    setLiveStats: (state, action) => {
      const statistics = action.payload?.statistics
      if (statistics) {
        return {
          ...state,
          liveStats: {
            ...statistics
          }
        }
      }
      return state
    },
    setAutoPlayEndAfterAnimation: (state, action) => {
      return {
        ...state,
        autoBetCurrentPlacedBet: null,
        autoBetSuccess: false,
        isLastWin: false,
        isLastLoss: false
      }
    },
    setAutoBetNoOfRounds: (state, action) => {
      return {
        ...state,
        autoBetNoOfRounds: action.payload
      }
    },
    setRoundResult: (state, action) => {
      return {
        ...state,
        isLastWin: action.payload.isLastWin,
        isLastLoss: action.payload.isLastLoss
      }
    },
    setStrategyInputs: (state, action) => {
      return {
        ...state,
        strategyInputs: action.payload
      }
    },
    callMineGameRoundEnd: (state, action) => {
      return {
        ...state,
        betLock: false,
        gameEnd: false,
        currentSelectedTiles: initialState.currentSelectedTiles,
        currentPlacedBet: initialState.currentPlacedBet,
        isLastWin: false,
        isLastLoss: false
      }
    },
    setAutoBetAmount: (state, action) => {
      return {
        ...state,
        strategyWatchers: { ...state.strategyWatchers, autoBetAmount: action.payload }
      }
    },
    addCurrentSelectedTiles: (state, action) => {
      return {
        ...state,
        currentSelectedTiles: action.payload
      }
    },
    removeCurrentSelectedTiles: (state, action) => {
      return {
        ...state,
        currentSelectedTiles: initialState.currentSelectedTiles
      }
    },
    stopAutoBet: (state, action) => {
      return {
        ...state,
        loading: false,
        autoBetNoOfRounds: initialState.autoBetNoOfRounds,
        isAutoPlayStarted: initialState.isAutoPlayStarted,
        autoBetSuccess: initialState.autoBetSuccess,
        autoBetCurrentPlacedBet: initialState.autoBetCurrentPlacedBet,
        isLastLoss: false,
        isLastWin: false
      }
    },
    resetLiveStates: (state, action) => {
      return {
        ...state,
        liveStates: {
          ...state.liveStates,
          wins: 0,
          wagered: 0.00,
          profit: 0.00,
          losses: 0
        }
      }
    },
    setMinesVolume: (state, action) => ({ ...state, minesVolume: action.payload }),
    // DEMO MODE BET
    demoMinePlaceBet: (state, action) => {
      const demoMineResult = generateRandomResult(action.payload.mineCount)
      return {
        ...state,
        currentPlacedBet: action.payload,
        betLock: true,
        mineExist: false,
        demoMineResult
      }
    },
    demoMineAutoBetPending: (state, action) => {
      return {
        ...state,
        autoBetSuccess: false,
        isAutoPlayStarted: true,
        autoBetCurrentPlacedBet: initialState.autoBetCurrentPlacedBet,
        loading: true,
        isLastWin: false,
        isLastLoss: false
      }
    },
    demoMineAutoPlaceBet: (state, action) => {
      let isStop = false
      const {
        betAmount,
        mineCount,
        mineGameSetting,
        playMineTileAudio,
        playNormalTileAudio,
        tiles: selectedTiles,
      } = action.payload

      const truthyStrategyInputs = pickBy(state.strategyInputs, value => Boolean(value) === true)
      let _netReturn = state.strategyWatchers.netReturn

      const _previousBetAmount = +(action.payload?.betAmount ?? 0)

      let _nextBetAmount = +(action.payload?.betAmount ?? 0)

      const demoMineResult = generateRandomResult(mineCount)

      // CHECK if any of the selected tile is a mine tile.
      const isMine = selectedTiles.some(tile => demoMineResult.includes(tile))
      const payout = calculateMineGameCashoutOdds(mineCount, selectedTiles?.length, mineGameSetting)
      const winningAmount = getPrecision(betAmount * payout)

      const demoResponse = {
        betAmount,
        mineCount,
        mineTiles: demoMineResult,
        winningAmount: isMine ? 0 : winningAmount,
        result: isMine ? BET_RESULT.LOST : BET_RESULT.WON
      }

      if (demoResponse.result === BET_RESULT.WON) {
        _netReturn = getPrecisionNumber(plus(_netReturn, minus(winningAmount, _previousBetAmount)))
      } else if (demoResponse.result === BET_RESULT.LOST) {
        _netReturn = getPrecisionNumber(minus(_netReturn, _previousBetAmount))
      }

      const _strategyWatchers = {
        totalBetAmount: getPrecisionNumber(plus(state.strategyWatchers.totalBetAmount, _previousBetAmount)),
        netReturn: _netReturn,
        autoBetAmount: _nextBetAmount
      }

      console.log("-=-=NET RESULT, ", _netReturn)
      Object.keys(truthyStrategyInputs).forEach(strategy => {
        switch (strategy) {
          case 'stopOnProfit':
            console.log("truthyStrategyInputs[strategy] ", truthyStrategyInputs[strategy])
            isStop = _netReturn >= truthyStrategyInputs[strategy]
            break
          case 'stopOnLoss':
            isStop = Math.abs(_netReturn) >= truthyStrategyInputs[strategy]
            break
          case 'maxBetAmount':
            isStop = _strategyWatchers.totalBetAmount >= truthyStrategyInputs[strategy]
            break
        }
      })

      demoResponse.result === BET_RESULT.WON ? playNormalTileAudio() : playMineTileAudio()

      if (+state.autoBetNoOfRounds - 1 === 0 || isStop) {
        return {
          ...state,
          loading: false,
          autoBetSuccess: true,
          isAutoPlayStarted: false,
          autoBetCurrentPlacedBet: demoResponse,
          strategyWatchers: initialState.strategyWatchers,
          autoBetNoOfRounds: initialState.autoBetNoOfRounds,
          currentSelectedTiles: initialState.currentSelectedTiles,
        }
      }

      return {
        ...state,
        loading: false,
        autoBetSuccess: true,
        strategyWatchers: _strategyWatchers,
        autoBetCurrentPlacedBet: demoResponse,
        autoBetNoOfRounds: state.autoBetNoOfRounds - 1,
      }
    },

    demoMineCancelBet: (state, _) => {
      return {
        ...state,
        currentPlacedBet: null,
        betLock: false,
        demoMineResult: null
      }
    },
    demoMineGameOpenTile: (state, action) => {
      if (action.payload.mineTile) {
        return {
          ...state,
          currentPlacedBet: action.payload,
          currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile],
          // myBetsData: [action.payload, ...state.myBetsData],
          gameEnd: true,
          demoMineResult: null,
          mineExist: action.payload.mineTile
        }
      }

      return {
        ...state,
        currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile]
      }
    },
    demoMineGameCashOut: (state, action) => {
      return {
        ...state,
        currentPlacedBet: action.payload,
        // myBetsData: [action.payload, ...state.myBetsData],
        gameEnd: true,
        demoMineResult: null
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMineGameLiveStats.fulfilled, (state, action) => {
        const statistics = action.payload?.statistics
        if (statistics) {
          return {
            ...state,
            liveStats: {
              ...statistics
            }
          }
        }
        return state
      })
      .addCase(getMineGamePreviousRoundState.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getMineGamePreviousRoundState.fulfilled, (state, action) => {
        if (action.payload?.hasUnfinishedGame) {
          const currentSelectedTiles = action.payload?.unfinishedGameBetDetails?.openTiles
          return {
            ...state,
            loading: false,
            currentSelectedTiles,
            currentPlacedBet: action?.payload?.unfinishedGameBetDetails,
            betLock: true
          }
        }

        return {
          ...state,
          loading: false
        }
      })
      .addCase(getMineGamePreviousRoundState.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(mineGamePlacedBet.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGamePlacedBet.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          currentPlacedBet: action.payload,
          betLock: true
        }
      })
      .addCase(mineGamePlacedBet.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(mineGameAutoBet.pending, (state, action) => {
        return {
          ...state,
          autoBetSuccess: false,
          isAutoPlayStarted: true,
          autoBetCurrentPlacedBet: initialState.autoBetCurrentPlacedBet,
          loading: true,
          isLastWin: false,
          isLastLoss: false
        }
      })
      .addCase(mineGameAutoBet.fulfilled, (state, action) => {
        let isStop = false
        const myBet = action.payload
        const liveData = { ...state.liveStates }
        const truthyStrategyInputs = pickBy(state.strategyInputs, value => Boolean(value) === true)

        if (myBet?.result === 'won') {
          liveData.wins = (liveData.wins || 0) + 1
        }
        else if (myBet?.result === 'lost') {
          liveData.losses = (liveData.losses || 0) + 1
        }

        if (!isNaN(parseFloat(myBet?.betAmount))) {
          liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(myBet?.betAmount)).toFixed(2)
        }

        if (!isNaN(parseFloat(myBet?.winningAmount)) && !isNaN(parseFloat(myBet?.betAmount))) {
          const profitDelta = (parseFloat(myBet?.winningAmount) - parseFloat(myBet?.betAmount)).toFixed(2)
          const totalprofit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
          liveData.profit = parseFloat(totalprofit)
        }

        let _netReturn = state.strategyWatchers.netReturn

        const _previousBetAmount = +(action.payload?.betAmount ?? 0)

        let _nextBetAmount = +(action.payload?.betAmount ?? 0)

        const _winningAmount = +(action.payload?.winningAmount ?? 0)

        if (action.payload?.result === BET_RESULT.WON) {
          _netReturn = getPrecisionNumber(plus(_netReturn, minus(_winningAmount, _previousBetAmount)))
        } else if (action.payload?.result === BET_RESULT.LOST) {
          _netReturn = getPrecisionNumber(minus(_netReturn, _previousBetAmount))
        }

        if (action.payload?.result === BET_RESULT.WON && state.strategyInputs.onWinEnabled) {
          const winPercentage = +state.strategyInputs.onWinIncreasePercent ?? 0
          _nextBetAmount = getPrecisionNumber(plus(_previousBetAmount, _previousBetAmount * winPercentage / 100))
        } else if (action.payload?.result === BET_RESULT.LOST && state.strategyInputs.onLossEnabled) {
          const lossPercentage = +state.strategyInputs.onLossIncreasePercent ?? 0
          _nextBetAmount = getPrecisionNumber(plus(_previousBetAmount, _previousBetAmount * lossPercentage / 100))
        }

        const _strategyWatchers = {
          totalBetAmount: getPrecisionNumber(plus(state.strategyWatchers.totalBetAmount, _previousBetAmount)),
          netReturn: _netReturn,
          autoBetAmount: _nextBetAmount
        }

        console.log("-=-=NET RESULT, ", _netReturn)
        Object.keys(truthyStrategyInputs).forEach(strategy => {
          switch (strategy) {
            case 'stopOnProfit':
              console.log("truthyStrategyInputs[strategy] ", truthyStrategyInputs[strategy])
              isStop = _netReturn >= truthyStrategyInputs[strategy]
              break
            case 'stopOnLoss':
              isStop = Math.abs(_netReturn) >= truthyStrategyInputs[strategy]
              break
            case 'maxBetAmount':
              isStop = _strategyWatchers.totalBetAmount >= truthyStrategyInputs[strategy]
              break
          }
        })

        if ((+state.autoBetNoOfRounds - 1 === 0) || isStop) {
          return {
            ...state,
            loading: false,
            autoBetSuccess: true,
            isAutoPlayStarted: false,
            autoBetCurrentPlacedBet: action.payload,
            autoBetNoOfRounds: initialState.autoBetNoOfRounds,
            strategyWatchers: initialState.strategyWatchers,
            currentSelectedTiles: initialState.currentSelectedTiles,
            liveStates: liveData
          }
        }
        return {
          ...state,
          autoBetSuccess: true,
          loading: false,
          autoBetCurrentPlacedBet: action.payload,
          autoBetNoOfRounds: state.autoBetNoOfRounds - 1,
          strategyWatchers: _strategyWatchers,
          liveStates: liveData
        }
      })
      .addCase(mineGameAutoBet.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          autoBetSuccess: false,
          isAutoPlayStarted: false,
          autoBetNoOfRounds: initialState.autoBetNoOfRounds,
          isLastWin: false,
          isLastLoss: false
        }
      })
      .addCase(getMyBetsMineGame.pending, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: true
        }
      })
      .addCase(getMyBetsMineGame.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: false,
          myBetsData: action.payload.rows
        }
      })
      .addCase(getMyBetsMineGame.rejected, (state, action) => {
        return {
          ...state,
          myBetsDataLoading: false
        }
      })
      .addCase(mineGameOpenTile.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGameOpenTile.fulfilled, (state, action) => {
        if (action.payload.mineTile) {
          const liveData = { ...state.liveStates }

          const myBet = action.payload

          if (myBet.result === 'won') {
            liveData.wins = (liveData.wins || 0) + 1
          }
          else if (myBet?.result === 'lost') {
            liveData.losses = (liveData.losses || 0) + 1
          }

          if (!isNaN(parseFloat(myBet?.betAmount))) {
            liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(myBet?.betAmount)).toFixed(2)
          }

          if (!isNaN(parseFloat(myBet?.winningAmount)) && !isNaN(parseFloat(myBet?.betAmount))) {
            const profitDelta = (parseFloat(myBet?.winningAmount) - parseFloat(myBet?.betAmount)).toFixed(2)
            const totalprofit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
            liveData.profit = parseFloat(totalprofit)
          }
          return {
            ...state,
            currentPlacedBet: action.payload,
            currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile],
            myBetsData: [action.payload, ...state.myBetsData],
            gameEnd: true,
            loading: false,
            liveStates: liveData
          }
        }
        return {
          ...state,
          currentSelectedTiles: [...state.currentSelectedTiles, action.payload.tile],
          loading: false
        }
      })
      .addCase(mineGameOpenTile.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          isLastWin: false
        }
      })
      .addCase(mineGameCashOut.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(mineGameCashOut.fulfilled, (state, action) => {
        const liveData = { ...state.liveStates }

        const myBet = action.payload

        if (myBet?.result === 'won') {
          liveData.wins = (liveData.wins || 0) + 1
        }
        else if (myBet?.result === 'lost') {
          liveData.losses = (liveData.losses || 0) + 1
        }

        if (!isNaN(parseFloat(myBet?.betAmount))) {
          liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(myBet?.betAmount)).toFixed(2)
        }

        if (!isNaN(parseFloat(myBet?.winningAmount)) && !isNaN(parseFloat(myBet?.betAmount))) {
          const profitDelta = (parseFloat(myBet?.winningAmount) - parseFloat(myBet?.betAmount)).toFixed(2)
          const totalprofit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
          liveData.profit = parseFloat(totalprofit)
        }

        return {
          ...state,
          currentPlacedBet: action.payload,
          myBetsData: [action.payload, ...state.myBetsData],
          loading: false,
          gameEnd: true,
          liveStates: liveData
        }
      })
      .addCase(mineGameCashOut.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(minGameTopBets.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(minGameTopBets.fulfilled, (state, action) => {
        return {
          ...state,
          topBetsData: action.payload?.topBets,
          loading: false
        }
      })
      .addCase(minGameTopBets.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default reducer

export {
  stopAutoBet,
  setStrategyInputs,
  setAutoBetNoOfRounds,
  setAutoPlayEndAfterAnimation,
  callMineGameRoundEnd,
  addCurrentSelectedTiles,
  removeCurrentSelectedTiles,
  setAutoBetAmount,
  setRoundResult,
  setLiveStats,
  setMinesVolume,
  resetLiveStates,
  demoMinePlaceBet,
  demoMineCancelBet,
  demoMineGameOpenTile,
  demoMineGameCashOut,
  demoMineAutoPlaceBet,
  demoMineAutoBetPending
}
